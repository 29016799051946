import React, { Component } from "react";
import Navbar from "./global-components/navbar"
import Page_header from "./global-components/page-header";
import Footer_v1 from "./global-components/footer-v1";
import NombreAurora from "./artemis-components/nombre-aurora";

const SobreNosotros =()=>{
    return (
        <div>
        <Navbar/>
        <Page_header headertitle="Sobre nosotros"/>
        <NombreAurora customclass="pd-top-120 bg-none" />
        <Footer_v1/>
        </div>
    )
}
export default SobreNosotros;