import React, { Component } from "react";
import { Link } from "react-router-dom";

class Navbar extends Component {
  render() {
    let publicUrl = process.env.PUBLIC_URL + "/";
    let imgattr = "logo";
    let anchor = "#";
    return (
      <div>
        <nav className="navbar navbar-area navbar-expand-lg nav-style-01">
          <div className="container nav-container">
            <div className="responsive-mobile-menu">
              <div className="logo-wrapper mobile-logo">
                <a href={anchor} className="logo">
                  <img src={publicUrl + "assets/img/logo.png"} alt={imgattr} />
                </a>
              </div>
              <div className="nav-right-content"></div>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#Riyaqas_main_menu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggle-icon">
                  <span className="line"></span>
                  <span className="line"></span>
                  <span className="line"></span>
                </span>
              </button>
            </div>
            <div className="collapse navbar-collapse" id="Riyaqas_main_menu">
              <div className="logo-wrapper desktop-logo">
                <a href="#" className="logo">
                  <img
                    src={publicUrl + "assets/img/aurora/auroratop.png"}
                    alt={imgattr}
                  />
                </a>
              </div>
              <ul className="navbar-nav">
                <li>
                  <Link to="/">Inicio</Link>
                </li>
                <li>
                  <Link to="/sobre-nosotros">Sobre Nosotros</Link>
                </li>
                <li>
                  <Link to="/services">Servicios</Link>
                </li>
                <li>
                  <Link to="/">Casos de Éxito</Link>
                </li>
                <li>
                  <Link to="/">Recursos</Link>
                </li>

                <li>
                  <Link to="/">Contáctanos</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="body-overlay" id="body-overlay"></div>
      </div>
    );
  }
}

export default Navbar;
