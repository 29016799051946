import React from 'react';
import Navbar from './global-components/navbar';
import SliderV1 from './section-components/slider-v1';
import FooterV1 from './global-components/footer-v1';

const Home_V1 = () => {
    return <div>
        <Navbar />
        <SliderV1 />
        <FooterV1 />
    </div>
}

export default Home_V1

