import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, HashRouter, Route, Switch } from "react-router-dom";
import HomeV1 from './components/home-v1';

import ServiciosPage from './components/servicios';
import SobreNosotros from './components/sobre-nosotros';

class Root extends Component {
    render() {
        return(
            <Router>
                <HashRouter basename="/">
                <div>
                <Switch>
                    <Route exact path="/" component={HomeV1} />
                    <Route path="/services" component={ServiciosPage} />
                    <Route path="/sobre-nosotros" component={SobreNosotros}/>
                </Switch>
                </div>
                </HashRouter>
            </Router>
        )
    }
}

export default Root;

ReactDOM.render(<Root />, document.getElementById('riyaqas'));
