import React from "react";
import sectiondata from "./servicios.json";
const Servicios = ({ customclass = "" }) => {
  const publicUrl = process.env.PUBLIC_URL + "/";
  const anchor = "#";
  const imgattr = "image";
  return (
    <div>
      <div className={`service-area ${customclass}`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-7 col-lg-10">
              <div className="section-title text-center margin-bottom-90">
                <h2
                  className="title wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.1s"
                >
                  {sectiondata.services.sectiontitle}{" "}
                  <span>{sectiondata.services.sectiontitle_color}</span>
                  {sectiondata.services.sectiontitle_2}
                </h2>
                <p
                  className="wow animated fadeInUp"
                  data-wow-duration="0.6s"
                  data-wow-delay="0.2s"
                >
                  {sectiondata.services.short_description}
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center custom-gutters-16 single-work-processing">
            <div className="col-xl-5 col-md-6 order-lg-12">
              <div className="thumb swp-right-thumb">
                <img
                  src={publicUrl + "assets/img/work-processing/1.png"}
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xl-4 col-md-6 order-lg-1 desktop-center-item">
              <div className="work-processing-details">
                <div className="section-title style-four">
                  <h2 className="title">
                    Desarrollo <span>de Software</span> y Páginas Web
                  </h2>
                  <p>
                    Diseñamos soluciones personalizadas, desde APIs robustas y
                    seguras hasta interfaces web modernas, para transformar tus
                    ideas en sistemas funcionales y atractivos.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center custom-gutters-16 single-work-processing">
            <div className="col-xl-5 col-md-6">
              <div className="thumb">
                <img
                  src={publicUrl + "assets/img/aurora/servicios/infra.jpg"}
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xl-4 col-md-6 desktop-center-item">
              <div className="work-processing-details">
                <div className="section-title style-four">
                  <h2 className="title">
                    <span>Infraestructura Cloud</span> y Servicios de Hosting
                  </h2>
                  <p>
                    Optimiza tu negocio con arquitecturas cloud escalables,
                    configuraciones personalizadas en Azure y servicios de
                    hosting confiables para mantener tu sistema siempre en
                    línea.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center custom-gutters-16 single-work-processing">
            <div className="col-xl-5 col-md-6 order-lg-12">
              <div className="thumb swp-right-thumb">
                <img
                  src={publicUrl + "assets/img/aurora/servicios/devops.jpg"}
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xl-4 col-md-6 order-lg-1 desktop-center-item">
              <div className="work-processing-details">
                <div className="section-title style-four">
                  <h2 className="title">
                    DevOps <span> y </span>Automatización
                  </h2>
                  <p>
                    Simplifica tus procesos de desarrollo con pipelines
                    eficientes, flujos automatizados y soluciones personalizadas
                    con <strong>Power Platform</strong> para ahorrar tiempo y
                    recursos.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center custom-gutters-16 single-work-processing">
            <div className="col-xl-5 col-md-6">
              <div className="thumb">
                <img
                  src={publicUrl + "assets/img/aurora/servicios/server.jpg"}
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xl-4 col-md-6 desktop-center-item">
              <div className="work-processing-details">
                <div className="section-title style-four">
                  <h2 className="title">
                    <span> Administración de Servidores</span> y Configuración
                    de Servicios
                  </h2>
                  <p>
                    Garantizamos el óptimo funcionamiento de tus servidores con
                    configuraciones personalizadas, mantenimiento continuo y
                    servicios adaptados a tus necesidades.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center custom-gutters-16 single-work-processing">
            <div className="col-xl-5 col-md-6 order-lg-12">
              <div className="thumb swp-right-thumb">
                <img
                  src={publicUrl + "assets/img/aurora/servicios/consultoria.jpg"}
                  alt="img"
                />
              </div>
            </div>
            <div className="col-xl-4 col-md-6 order-lg-1 desktop-center-item">
              <div className="work-processing-details">
                <div className="section-title style-four">
                  <h2 className="title">
                    Consultoría <span> y Soluciones Personalizadas </span>
                  </h2>
                  <p>
                    Ofrecemos análisis detallados y soluciones a medida para
                    integrar y optimizar plataformas, mejorar procesos
                    empresariales y modernizar tus sistemas.
                  </p>
                  <div className="read-more">
                    <a href="/">
                     Conoce más <img
                        src={publicUrl + "assets/img/service/arrow.png"}
                        alt={imgattr}
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Servicios;
