import React from "react";
import Navbar from "./global-components/navbar";
import Page_header from "./global-components/page-header";
import Footer_v1 from "./global-components/footer-v1";
import Servicios from "./artemis-components/servicios";

const ServiciosPage = () => {
  
  return (
    
    <div>
      <Navbar />
      <Page_header headertitle="Servicios" />
      <Servicios  customclass="pd-top-112"/>
      <Footer_v1 />
    </div>
  );
};

export default ServiciosPage;
