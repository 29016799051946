import React from "react";
import sectiondata from "../../data/sections.json";
import parse from "html-react-parser";
const NombreAurora = ({ customclass = "" }) => {
  const data = sectiondata.videov2;
  const publicUrl = process.env.PUBLIC_URL + '/';
  const image_url= "assets/img/aurora/about.jpg";
  const imgattr = 'image';
  return (
    <div className={"sba-work-area img-with-video-area " + customclass}>
      <div className="container">
        <div className="row">
          <div
            className="col-xl-7 col-lg-6 order-lg-1 wow animated fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.3s"
          >
            <div className="img-with-video">
              <div className="img-wrap">
                <img src={publicUrl + image_url} alt={imgattr} />
              </div>
            </div>
          </div>
          <div className="col-xl-5 col-lg-6 order-lg-12 desktop-center-item">
            <div
              className="section-title ml-xl-4 mb-lg-0 style-two text-lg-left text-center wow animated fadeInRight"
              data-wow-duration="1s"
              data-wow-delay="0.3s"
            >
              <h2 className="title">
                ¿Por qué el nombre <span>Aurora TI</span> ?
              </h2>
              <p>
                El nombre <strong>AuroraTI</strong> tiene un significado
                profundo y personal. "Aurora" es el nombre de mi hija, quien
                representa mi mayor inspiración, y "T.I." proviene de
                Tecnologías de la Información, mi pasión y especialidad.{" "}
              </p>
              <p>
                {" "}
                Renombré el proyecto de <strong>AdagioTI</strong> a
                <strong> AuroraTI</strong> en 2017 para reflejar el vínculo con
                mi hija y mi compromiso de trabajar con la misma dedicación con
                la que cuido lo más importante en mi vida. En{" "}
                <strong>AuroraTI</strong>, no solo implementamos tecnología;
                creamos soluciones que te simplifican la vida y te permiten
                concentrarte en lo que realmente importa, tu negocio.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NombreAurora;
